<template>
  <div
    :class="['copyright-practice-nav', isEnterIndex ? 'is-enter-index' : '']"
  >
    <ul class="copyright-practice-nav-ul">
      <li
        class="copyright-practice-nav-ul-li"
        v-for="(item, index) in navLang"
        :key="index"
      >
        <span
          @click="handleClick(index, item.name)"
          :class="i == index ? 'color' : ''"
          >{{ item.title }}</span
        >
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: "CopyrightPracticeNav",
  data() {
    return {
      i: -1,
      //顶部标签导航语言
      navLang: [
        {
          title: "我的关注",
          name: "MyAttention"
        },
        {
          title: "代办委托",
          name: "AgencyEntrustment"
        },
        // {
        //   title: "购物车",
        // },
        {
          title: "订单中心",
          name: "OrderCenter"
        },
        {
          title: "我的收藏",
          name: "MyCollection"
        }
      ]
    };
  },
  computed: {
    isEnterIndex() {
      return this.$route.name === "CopyrightPracticeIndex";
    }
  },
  methods: {
    handleClick(index, name) {
      this.i = index;
      this.$router.push({
        name
      });
    }
  },
  watch: {
    $route(to, from) {
      if (
        to.path === "/copyrightService/copyrightPractice/copyrightPracticeIndex"
      ) {
        this.i = -1;
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.copyright-practice-nav {
  @include flex-start;
  height: 60px;

  padding-left: 25px;
  border-bottom: 1px solid #eceff1;
  &.is-enter-index {
    border: none;
  }
  &-ul {
    @include flex-start;
    &-li {
      cursor: pointer;
      &:first-child ~ & {
        margin-left: 30px;
      }
      span {
        font-size: 14px;
        font-weight: 400;
        color: #666666;
      }
    }
  }

  .color {
    color: #409eff;
  }
}
</style>

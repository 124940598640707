<template>
  <div class="copyright-practice">
    <div class="copyright-practice-top">
      <CopyrightPracticeNav />
    </div>
    <router-view />
  </div>
</template>

<script>
import CopyrightPracticeNav from "components/copyright-service/CopyrightPracticeNav";
export default {
  name: "CopyrightPractice",
  components: {
    CopyrightPracticeNav,
  },
};
</script>

<style lang="scss" scoped>
.copyright-practice{
  min-height:calc(100vh - 126px);
  padding-bottom:20px;
  box-sizing:border-box;
}
</style>
